import React from "react";
import { RoomAvailability } from "../../common/models";
import { cToUsdStrPref, classNames } from "../utils";

import './HotelRoomAvailability.scss';

interface Props {
  availability: RoomAvailability;
}

export const HotelRoomAvailability: React.FC<Props> = ({ availability: { available, availableAt, roomRate } }) => {
  const soldOut = !available;
  const day = availableAt.toLocaleString('en-US', { weekday: 'short', timeZone: 'UTC' });
  const date = availableAt.toLocaleString('en-US', { month: 'short', day: 'numeric', timeZone: 'UTC' });

  return (
    <div className={classNames({ 'sold-out': soldOut }, "hotel-room-availability")}>
      <div className="hotel-room-availability-day">{day}</div>
      <div className="hotel-room-availability-date">{date}</div>
      <div className="hotel-room-availability-price">{soldOut ? 'Sold Out' : cToUsdStrPref(roomRate)}</div>
    </div>
  )
};