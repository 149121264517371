import React, { useCallback, useState } from 'react';
import { MaterialIcon, MaterialIconText } from '@conventioncatcorp/common-fe';
import { Card, CardHeader, Badge, Button, UncontrolledTooltip, CardBody } from 'reactstrap';
import { useHistory } from 'react-router';

import './Collapse.scss';

interface CollapseActionButton {
  text: string;
  id: string;
  onClick?: () => void;
  url?: string;
  color?: string;
  disabled?: boolean;
  hoverText?: string;
}

interface CollapseProps {
  children: JSX.Element | JSX.Element[];
  defaultOpen?: boolean;
  title: string;
  countBadge?: number;
  countBadgeColor?: string;
  actions?: CollapseActionButton[];
  warningBanner?: string | JSX.Element;
}

export const Collapse: React.FC<CollapseProps> = ({
  countBadge,
  countBadgeColor = (!countBadge || countBadge === 0) ? 'secondary' : 'primary',
  title,
  defaultOpen,
  children,
  actions,
  warningBanner,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen || false);
  const toggleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  return (
    <Card className="mb-3 collapse-element">
      <CardHeader className="collapse-header" onClick={toggleOpen}>
        {title}
        {(countBadge !== undefined) && (
          <Badge color={countBadgeColor}>{countBadge}</Badge>
        )}
        <div className="float-right action-list" style={{ marginBottom: '-12px' }}>
          <div>
            {actions?.map((action, i) => (
              <CollapseActionButton
                key={i}
                {...action}
              />
            ))}
          </div>
          <MaterialIcon
            name={isOpen ? "expand_less" : "expand_more"}
            onClick={toggleOpen}
          />
        </div>
      </CardHeader>
      {isOpen && (
        <>
          {warningBanner && (
            <div className="warning-banner">
              <MaterialIconText name="warning">
                {warningBanner}
              </MaterialIconText>
            </div>
          )}
          <CardBody>
            {children}
          </CardBody>
        </>
      )}
    </Card>
  )
};

const CollapseActionButton: React.FC<CollapseActionButton> = ({
  id, text, onClick, url, color = 'light', disabled, hoverText,
}) => {
  const history = useHistory();  
  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick();
    } else if (url) {
      history.push(url);
    }
  }, [onClick, url]);

  return (
    <>
      <Button
        id={id}
        color={color}
        size='sm'
        style={{ marginTop: '-5px' }}
        disabled={disabled}
        onClick={handleOnClick}
        outline
      >
        {text}
      </Button>
      {hoverText && (
        <UncontrolledTooltip placement="bottom" target={id} trigger="hover">
          {hoverText}
        </UncontrolledTooltip>
      )}
    </>
  )
};
