import { RoomAvailability, RoomBlock } from "../../common/models";

interface AvailabilityProps {
  rooms: RoomBlock[];
  checkIn?: Date;
  checkOut?: Date;
}

export const getAvailability = (availability: RoomAvailability[], start: Date, end: Date): RoomAvailability[] => {
  let foundCheckInDate = false;

  const filteredAvailability = availability.filter(availability => {
    const availableAt = availability.availableAt;

    const checkInCompare = availableAt.getTime() - start.getTime();
    const checkOutCompare = availableAt.getTime() - end.getTime();

    // If the check-in date is within 26 hours of the availableAt date, we're within the correct date range and can assume the check-in date is valid.
    if (checkInCompare <= 93600000)
      foundCheckInDate = true;

    return checkInCompare >= 0 && checkOutCompare < 0;
  });

  return foundCheckInDate ? filteredAvailability : [];
};

/**
 * @description Returns a list of rooms that are available for the given check-in and check-out dates.
 * 
 * @param {AvailabilityProps} { rooms, checkIn, checkOut }
 * @param {RoomBlock[]} rooms - List of rooms to check availability for.
 * @param {Date} checkIn - Check-in date.
 * @param {Date} checkOut - Check-out date.
 * 
 * @returns {RoomBlock[]} List of rooms that are available for the given check-in and check-out dates.
 */
export const getAvailableRooms = ({ rooms, checkIn, checkOut }: AvailabilityProps): RoomBlock[] => {
  return rooms.filter(roomBlock => {
    if (!checkIn || !checkOut)
      return true;

    const availability = getAvailability(roomBlock.availability, checkIn, checkOut);
    return (
      availability.length > 0 &&
      availability.every(availability => (
        availability.available || (
          availability.roomCount && availability.roomCount > 0
        )
      ))
    );
  });
};

export const getLowestAveragePrice = (rooms: RoomBlock[], start: Date, end: Date): number => {
  const availableRooms = getAvailableRooms({ rooms, checkIn: start, checkOut: end });

  const avgPrice = availableRooms.reduce((lowestPrice, room) => {
    const availability = getAvailability(room.availability, start, end);
    
    if (availability.some(availability => !availability.available))
      return lowestPrice;

    const averagePrice = availability.reduce((total, availability) => total + availability.roomRate, 0) / availability.length;

    return averagePrice < lowestPrice ? averagePrice : lowestPrice;
  }, Infinity);

  return avgPrice === Infinity ? 0 : avgPrice;
};
