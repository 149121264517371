import React from "react";
import { Route } from "react-router";
import { List } from "./List";

export const Router: React.FC = () => {
  return (
    <>
      <Route path="/organizer/room-blocks" exact component={List} />
    </>
  )
};
