import React, { Component } from 'react';
import { classNames } from '../../utils';

interface MaterialProps {
  name: string;
  large?: boolean;
  medium?: boolean;
  type?: 'muted' | 'primary' | 'success' | 'info' | 'warning' | 'danger' | 'white';
  className?: string;
}

export class MaterialIcon extends Component<MaterialProps> {
  public override render() {
    const { props } = this;
    return (
      <span
        className={
          classNames({
            large: !!props.large,
            medium: !!props.medium,
            [`text-${this.props.type}`]: !!props.type,
          },
          this.props.className || '',
          'material-icons')
        }
      >
        {this.props.name}
      </span>
    );
  }
}
