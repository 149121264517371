import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Badge, Card, CardBody, Col, Row } from 'reactstrap';
import { Collapse, EmptyState, ExpandableText, MetricBlock } from '../../../component/common';
import { Link } from 'react-router-dom';
import { InlineHelp } from '@conventioncatcorp/common-fe/dist/components/InlineHelp';
import { Config, Hotel, HotelFee, RoomBlock } from '../../../../common/models';
import { cToUsdStrPref, captureError, formatAddress, getAvailability, getAvailableRooms, isResourceError } from '../../../utils';
import { useConfig } from '../../../services';

interface Params {
  id: string;
}

export const View: React.FC = () => {
  const { id } = useParams<Params>();
  const history = useHistory();
  const config = useConfig();

  const [hotel, setHotel] = useState<Hotel | null>(null);

  useEffect(() => {
    api
      .getHotel(parseInt(id))
      .then(setHotel)
      .catch((error: Error) => {
        if (isResourceError(error, 'Hotel')) {
          history.push('/organizer/hotels');
          return;
        }

        captureError(error);
      });
  }, []);

  if (!hotel) {
    return (
      <EmptyState
        title="Loading..."
        details={["Just a sec while we find that hotel..."]}
        icon="hourglass_empty"
      />
    )
  }

  const isSoldOut = getAvailableRooms({
    rooms: hotel.roomBlocks!,
    checkIn: config?.convention.startAt,
    checkOut: config?.convention.endAt,
  }).length === 0;

  return (
    <div>
      <Card className="mb-3">
        <CardBody className="align-center card-item-header">
          <div>
            <h4 className="mb-1">Hotel #{id} - {hotel.name}</h4>
            <div className="badge-list">
              {hotel.visibility ?
                <Badge color="success">Publicly Visible</Badge> :
                <Badge color="warning">Hidden from Attendees</Badge>}
              {hotel.isMainHotel && <Badge color="primary">Main Hotel</Badge>}
              {!hotel.managedBy ? 
                <Badge color="secondary">Manual</Badge> : (
                  <Badge color="warning">
                    3rd Party: {hotel.integration!.name} ({hotel.integration!.type})
                  </Badge>
                )}
              {isSoldOut && <Badge color="danger">Sold Out</Badge>}
            </div>
          </div>
        </CardBody>
      </Card>
      <Row>
        <Col xs={12} lg={4}>
          <Card>
            <CardBody>
              <h5>Address</h5>
              <p>{formatAddress(hotel)}</p>

              <h5>Website</h5>
              <p>{hotel.website ?? <i>Not provided</i>}</p>

              <h5>Phone</h5>
              <p>{hotel.phone ?? <i>Not provided</i>}</p>

              <h5>Description</h5>
              <div className="mb-3">
                <ExpandableText
                  text={hotel.description ?? 'No description available.'}
                  maxLength={128}
                /> 
              </div>

              <h5>
                Managed By
                <div className="inline-help">
                  <InlineHelp>
                    <p>Managed by indicates which third-party integration is managing this hotel.</p>
                    <p>
                      If this is "Manual", then the hotel is managed entirely within BookingBun and
                      has been manually created.
                    </p>
                    <p>
                      If this hotel is managed by a third-party integration, some fields may not be
                      editable.
                    </p>
                  </InlineHelp>
                </div>
              </h5>
              <p>
                {!hotel.managedBy ? 'Manual' : (
                  <Link to={`/organizer/integrations/${hotel.integration!.id}`}>
                    {hotel.integration!.name} ({hotel.integration!.type})
                  </Link>
                )}
              </p>

              <hr />
              <div>
                <small>
                  <strong>Created</strong> {hotel.createdAt!.toLocaleString()}
                </small>
              </div>
              <div>
                <small>
                  <strong>Last updated</strong> {hotel.updatedAt!.toLocaleString()}
                </small>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} lg={8}>
          <HotelStats config={config!} hotel={hotel} />
          <Collapse
            title="Surcharge & Taxes"
            countBadge={hotel.fees.length}
            defaultOpen={hotel.fees.length === 0}
            actions={[{
              text: 'Add Surcharge',
              id: 'add-tax-surcharge',
              color: 'light',
              disabled: true,
              hoverText: `Surcharges and taxes cannot be added or
                removed as this hotel is managed by a third-party integration.`,
            }]}
            warningBanner={(
              <>Surcharges are <strong>read only</strong> as this hotel is managed by a third-party integration.</>
            )}
          >
            <Row>
              {hotel.fees.map(fee => (
                <SurchargeItem
                  key={fee.id}
                  fee={fee}
                />
              ))}
              {hotel.fees.length === 0 && (
                <Col xs={12}>
                  <div>This hotel does not have any surcharges or taxes.</div>
                </Col>
              )}
            </Row>
          </Collapse>
          <Collapse
            title="Room Blocks"
            countBadge={hotel.roomBlocks?.length ?? 0}
            defaultOpen={hotel.roomBlocks?.length === 0}
            actions={[{
              text: 'Add Room Block',
              id: 'add-room-block',
              color: 'light',
              disabled: true,
              hoverText: `Room blocks cannot be added or removed as
                this hotel is managed by a third-party integration.`,
            }]}
            warningBanner={(
              <>Room blocks are <strong>read only</strong> as this hotel is managed by a third-party integration.</>
            )}
          >
            <Row>
              {hotel.roomBlocks?.map(room => (
                <RoomBlockItem
                  key={room.id}
                  room={room}
                  config={config!}
                />
              ))}
              {hotel.roomBlocks?.length === 0 && (
                <Col xs={12}>
                  <div>This hotel does not have any room blocks.</div>
                </Col>
              )}
            </Row>
          </Collapse>
        </Col>
      </Row>
    </div>
  );
}

interface SurchargeItemProps {
  fee: HotelFee;
}

const SurchargeItem: React.FC<SurchargeItemProps> = ({ fee }) => {
  const getFeeText = useCallback(() => {
    if (fee.type === 'percentage') {
      return `${fee.value}% of ${fee.applyPerNight ? 'each night of stay' : 'stay total'}`;
    }

    return `$${cToUsdStrPref(fee.value)} per ${fee.applyPerNight ? 'night of stay' : 'stay'}`;
  }, [fee]);

  return (
    <Col xs={12} className="collapse-item">
      {/* <div className="float-right action-list">
        <MaterialIcon name="edit" />
        <MaterialIcon name="delete" />
      </div> */}
      <h5>{fee.name} <small>({fee.isTax ? 'Tax' : 'Surcharge'})</small></h5>
      <div>{getFeeText()}</div>
    </Col>
  );
};

interface RoomBlockItemProps {
  room: RoomBlock;
  config: Config;
}

const RoomBlockItem: React.FC<RoomBlockItemProps> = ({ config, room }) => {
  const availableRooms = getAvailability(
    room.availability,
    config.convention.startAt,
    config.convention.endAt,
  );
  let coreNights = Math.min(...availableRooms.map(a => a.roomCount!));
  if (coreNights === Infinity) {
    coreNights = 0;
  }
  const isSoldOut = coreNights === 0;

  return (
    <Col xs={12} className="collapse-item">
      <h5>{room.name} <small>({room.maxPersons} {room.maxPersons === 1 ? 'person' : 'people'} max)</small></h5>
      <div className="badge-list">
        {room.visibility ? <Badge color="success">Publicly Visible</Badge> : <Badge color="warning">Hidden from Attendees</Badge>}
        {isSoldOut ? <Badge color="danger">Sold Out</Badge> : <Badge color="info">{coreNights} Room{coreNights === 1 ? '' : 's'} Available</Badge>}
      </div>
    </Col>
  );
};

interface HotelStatsProps {
  hotel: Hotel;
  config: Config;
}

const HotelStats: React.FC<HotelStatsProps> = ({ config, hotel }) => {
  const availableRooms = hotel.roomBlocks?.reduce((acc, room) => {
    const availableRooms = getAvailability(
      room.availability,
      config.convention.startAt,
      config.convention.endAt,
    );
    const coreNights = Math.min(...availableRooms.map(a => a.roomCount!));
    return acc + (coreNights === Infinity ? 0 : coreNights);
  }, 0);

  const roomsReserved = (hotel.reservations ?? []).length;
  const totalRooms = availableRooms ?? 0 + roomsReserved;
  const uptakeRate = (roomsReserved >= 0 && totalRooms > 0) ? (roomsReserved / totalRooms) * 100 : 100;

  return (
    <Row className="mb-1">
      <Col xs={12} lg={4}>
        <MetricBlock
          title="Available Rooms"
          value={availableRooms?.toString() ?? '0'}
          icon="hotel"
        />
      </Col>
      <Col xs={12} lg={4}>
        <MetricBlock
          title="Rooms Booked"
          value={roomsReserved}
          icon="hotel"
        />
      </Col>
      <Col xs={12} lg={4}>
        <MetricBlock
          title="Uptake Rate"
          value={`${uptakeRate.toFixed(2)}%`}
          icon="assessment"
        />
      </Col>
    </Row>
  );
};
